import { Avatar, SxProps, Theme } from '@mui/material';
import { ExpertScore } from '../../types/saved_product_analyses';

type StandardSentimentIconProps = {
  score: ExpertScore;
  sx?: SxProps<Theme>;
};
export default function StandardSentimentIcon({
  score,
  sx = {},
}: StandardSentimentIconProps) {
  let color = 'grey';
  let stringScore = score.value as string;
  const lscore = stringScore.toLowerCase();
  // Letter scores
  if (lscore === 'green') color = '#009d4e';
  else if (lscore === 'yellow') color = '#ff9e16';
  else if (lscore === 'red') color = '#e63c2f';

  return (
    <Avatar sx={{ bgcolor: color, border: '0.5px solid black', ...sx }}>
      {' '}
    </Avatar>
  );
}
