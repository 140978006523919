import { SxProps, Theme } from '@mui/material';
import EwgIcon from './EwgIcon';
import StandardSentimentIcon from './StandardSentimentIcon';
import { ExpertScore } from '../../types/saved_product_analyses';

type SentimentIconProps = {
  score: ExpertScore;
  size?: number;
  sx?: SxProps<Theme>;
};

export default function SentimentIcon({
  score,
  size = 40,
  sx = {},
}: SentimentIconProps) {
  const SX = { ...sx, width: size * 0.75, height: size * 0.75 };
  if (!score?.value) return null;
  if (score.system === 'ewg-skindeep') {
    const arrScore = score.value as number[];
    return (
      <EwgIcon
        sx={{ width: size, height: size }}
        min={arrScore[0]}
        max={arrScore[1]}
      />
    );
  }
  return <StandardSentimentIcon score={score} sx={SX} />;
}
