import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useInIframe } from '../../../hooks/util/in-iframe-hook';

type IngredientsInspectorProps = {
  ingredients: string;
  setIngredients: (ingredients: string) => void;
  disabled?: boolean;
};
export function IngredientsInspector({
  ingredients,
  setIngredients,
  disabled = false,
}: IngredientsInspectorProps) {
  const [helpOpen, setHelpOpen] = useState(false);
  const noIngredients = ingredients.trim().length === 0;

  return (
    <PaperAnalyze sx={{ p: { xs: 1, sm: 2 }, width: 1 }}>
      <Stack direction="column">
        <Box>
          <TextField
            fullWidth
            value={ingredients}
            onChange={(e) => setIngredients(e.target.value)}
            multiline
            maxRows={8.6}
            disabled={disabled}
          />
        </Box>
        <Stack
          onClick={() => setHelpOpen(true)}
          direction="row"
          spacing={1}
          alignItems={'center'}
          sx={{ mt: 2, cursor: 'pointer' }}
        >
          <LightbulbIcon color="primary" />
          <Box>
            <Typography
              color={'grey.800'}
              sx={{ lineHeight: '19px', fontSize: 14, fontWeight: 400 }}
            >
              {noIngredients
                ? 'Copy paste the ingredient list above'
                : 'Don’t see the right information?'}
            </Typography>
          </Box>
        </Stack>
        <HelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
      </Stack>
    </PaperAnalyze>
  );
}

const HelpDialog = ({ open, onClose }) => {
  const [inIframe] = useInIframe();

  const webMessage = (
    <>
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Some sites only display ingredients after clicking a specific button. In
        that case, just copy paste the ingredients information into the
        ingredients text box. No need to format anything! And make sure to
        include any information after the ingredient list, like "* Organic" that
        indicates ingredients with a "*" are organic.
      </Typography>
      <br />
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Or try the CountOn browser extension! It works faster and has direct
        access to the website, so you can display the ingredients before running
        the analysis.
      </Typography>
    </>
  );

  const extMessage = (
    <>
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        Most frequently an issue occurs when a site has hidden some information
        (like ingredients) in a separate tab or collapsable section. To fix
        this, refresh this page, find the ingredients and make sure the full
        list is visible then click "Analyze" again from the extension.
      </Typography>
      <br />
      <Typography sx={{ lineHeight: 1.3, fontSize: { xs: 14, sm: 16 } }}>
        If that still does not work you can always add the product and
        ingredient information manually by copying and pasting into the
        Ingredient field in the extension and then click "Continue to Analysis"
      </Typography>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title" sx={{ lineHeight: 1.3 }}>
        Not seeing the right information?
      </DialogTitle>
      <DialogContent>{inIframe ? extMessage : webMessage}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
