import log from 'loglevel';
import config from '../../configs/aws-config';
import { logError } from '../../lib/errorLib';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from '../use-auth';
import { API } from 'aws-amplify';
import { useGuestId } from '../guest-id-hook';
import { useInIframe } from '../util/in-iframe-hook';
import {
  Ingredient,
  SavedProductAnalysis,
} from '../../types/saved_product_analyses';

type AnalyzePageFetchBody = {
  url: string;
  connectionId?: string;
};

export type FetchedPage = {
  markdown: string;
  url: string;
  title: string;
  html?: string;
};

type AnalyzePageFetchResponse = {
  content: FetchedPage;
  actor: string;
};

function fetchPage(
  body: AnalyzePageFetchBody,
): Promise<AnalyzePageFetchResponse> {
  log.debug('fetching page', body.url);

  const params = new URLSearchParams({
    api_key: 'N/A',
    url: body.url,
    save: 'false',
    account_name: 'junk',
    connection_id: body.connectionId || '',
  });

  // Append the parameters to the URL
  const urlWithParams = `${
    config.ai.CORE_URL + 'fetch_page'
  }?${params.toString()}`;

  return fetch(urlWithParams, {
    method: 'GET',
  }).then((response) => {
    log.debug('fetchPage response', response);
    return response.json();
  });
}

export function useFetchPage(
  url: string,
  connectionId?: string,
  enabled = true,
) {
  const review = useQuery(
    ['fetch-page', url],
    () => fetchPage({ url, connectionId }),
    {
      enabled: !!url && enabled,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => log.debug('useFetchPage data', data),
      onError: (error) => logError(error),
    },
  );
  return review;
}

////////////////////////////////////////

export enum AnalysisMode {
  URL = 'url',
  MANUAL = 'manual',
  MARKDOWN = 'page_md',
}

type CreateStubBody = {
  url: string;
  requester?: string; // 'unknown',
  mode?: AnalysisMode;
  location?: string; // 'website' or 'ext'
  account_names?: string[];
};
type CreateStubResponse = {
  status: 'ok' | 'error';
  analysis: SavedProductAnalysis;
};

/**
 * Creates an empty analysis record in the DB. Indicates an analysis has been
 * started, but nothing has been done yet. Importantly, returns the analysis_id
 * for use in the front end.
 * @param manualMode
 * @param mutOptions
 * @returns
 */
export function useCreateAnalysisStubMutation(
  mutOptions?: UseMutationOptions<CreateStubResponse, Error, CreateStubBody>,
) {
  const { isAuthenticated } = useAuth();
  const { guest_id } = useGuestId();
  const { user } = useAuth();
  const [inIframe] = useInIframe();

  const requester = user?.email || guest_id;

  const createAnalysisStub = (body: CreateStubBody) => {
    log.debug('Creating analysis stub', body);
    const apiPath = `${isAuthenticated ? '' : '/guest'}/product-analyses`;
    return API.post('trustable', apiPath, {
      body: {
        guest_id,
        requester,
        location: inIframe ? 'ext' : 'website',
        mode: 'url',
        ...body,
      },
    });
  };

  const mutation = useMutation<CreateStubResponse, Error, CreateStubBody>(
    createAnalysisStub,
    {
      onSuccess: (data) => {
        log.debug('Created analysis stub', data);
      },
      onError: (error) => {
        logError('Failed to create analysis stub', error);
      },
      ...mutOptions,
    },
  );
  return mutation;
}
////////////////////////////////////////

type ParseIngredientsBody = {
  ingredients: string;
};

export function useParseIngredientsMutation() {
  const parseIngredients = async (body: ParseIngredientsBody) => {
    const endpoint = config.ai.PAGE_INSPECTOR_URL + 'parse_ingredients';
    log.debug('startAnalysis', endpoint, body);
    return fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  const mutation = useMutation<Ingredient, Error, ParseIngredientsBody>({
    mutationKey: ['parseIngredients'],
    mutationFn: parseIngredients,
  });
  return mutation;
}

////////////////////////////////////////

type StartManualAnalysisBody = {
  analysisId: string;
  accountNames: string[];
  connectionId?: string;
  brand?: string;
  title?: string;
  description?: string;
  ingredients?: string;
};

export function useStartManualAnalysisMutation() {
  const startManualAnalysis = async (body: StartManualAnalysisBody) => {
    const endpoint = config.ai.PAGE_INSPECTOR_URL + 'start_manual_analysis';
    log.debug('startManualAnalysis', endpoint, body);
    return fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        analysis_id: body.analysisId,
        connection_id: body.connectionId,
        account_names: body.accountNames,
        brand: body.brand,
        title: body.title,
        description: body.description,
        ingredients: body.ingredients,
      }),
    }).then((response) => response.json());
  };

  const mutation = useMutation<
    SavedProductAnalysis,
    Error,
    StartManualAnalysisBody
  >({
    mutationKey: ['startManualAnalysis'],
    mutationFn: startManualAnalysis,
  });
  return mutation;
}

type StartAnalysisBody = {
  url: string;
  analysisId: string;
  accountNames: string[];
  connectionId?: string;
  markdown?: string;
};

export function useStartUrlAnalysisMutation() {
  const startAnalysis = async (body: StartAnalysisBody) => {
    const endpoint = config.ai.PAGE_INSPECTOR_URL + 'start_url_analysis';
    log.debug('startAnalysis', endpoint, body);
    return fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        url: body.url,
        analysis_id: body.analysisId,
        connection_id: body.connectionId,
        account_names: body.accountNames,
        page_md: body.markdown,
      }),
    }).then((response) => response.json());
  };

  const mutation = useMutation<SavedProductAnalysis, Error, StartAnalysisBody>({
    mutationKey: ['startAnalysis'],
    mutationFn: startAnalysis,
  });
  return mutation;
}

type AddIngredientAnalysisBody = {
  analysisId: string;
  ingredients?: string;
  connectionId?: string;
};

/**
 * Add an analysis of ingredients to an existing analysis. If ingredients are
 * provided, it should mean that the AI scraped ingredients were in error.
 * @returns
 */
export function useAddIngredientAnalysisMutation() {
  const addIngredientAnalysis = async (body: AddIngredientAnalysisBody) => {
    const endpoint = config.ai.PAGE_INSPECTOR_URL + 'add_ingredient_analysis';
    log.debug('addIngredientAnalysis', endpoint, body);
    return fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        analysis_id: body.analysisId,
        ingredients: body.ingredients,
        connection_id: body.connectionId,
      }),
    }).then((response) => response.json());
  };

  const mutation = useMutation<
    SavedProductAnalysis,
    Error,
    AddIngredientAnalysisBody
  >({
    mutationKey: ['addIngredientAnalysis'],
    mutationFn: addIngredientAnalysis,
  });
  return mutation;
}
