import { Accordion, AccordionSummary, Grid, Typography } from '@mui/material';
import { PaperAnalyze } from './PaperAnalyze';
import SelectableExpertTile from '../../shared/SelectableExpertTile';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SelectExpertListProps = {
  accountNames: string[];
  selectedAccountNames: string[];
  setSelectedAccountNames?: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
};

/**
 * Displays the accountNames in a grid of SelectableExpertTile components. For
 * logged in users, it ignores the selected prop and relies on if the account is
 * trusted or not. The logged in user can change the count on status though. For
 * guests, the user can select and deselect the account. This looks the same as
 * counting on and off, but is not saved anywhere.
 * @param param0
 * @returns
 */
export default function SelectExpertList({
  accountNames,
  selectedAccountNames,
  setSelectedAccountNames,
  disabled = false,
}: SelectExpertListProps) {
  const { user } = useAuth();

  const [expanded, setExpanded] = useState(!disabled);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  const handleSelected = (selected: boolean, accountName: string) => {
    if (!setSelectedAccountNames) return;
    if (selected) {
      setSelectedAccountNames((prev) =>
        prev.includes(accountName) ? prev : [...prev, accountName],
      );
    } else {
      setSelectedAccountNames((prev) =>
        prev.filter((name) => name !== accountName),
      );
    }
  };

  return (
    <PaperAnalyze sx={{ p: { xs: 0.25, sm: 0.5 }, width: 1 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Typography
            noWrap
            sx={{
              fontSize: 24,
              fontWeight: 500,
            }}
          >
            {user?.active_account ? 'Your sources' : 'Choose your sources'}
          </Typography>
        </AccordionSummary>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          wrap="wrap"
          width={1}
          sx={{
            pointerEvents: disabled ? 'none' : 'auto',
            opacity: disabled ? 0.5 : 1,
            p: 2,
          }}
        >
          {/* Example elements */}
          {accountNames.map((accountName) => (
            <Grid item key={accountName}>
              <SelectableExpertTile
                accountName={accountName}
                selected={selectedAccountNames.includes(accountName)}
                setSelected={(selected) =>
                  handleSelected(selected, accountName)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Accordion>
    </PaperAnalyze>
  );
}
