import { Box, Button, Stack, Typography } from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import {
  AnalysisMode,
  useCreateAnalysisStubMutation,
} from '../../hooks/ai/analyze-page-fetch';
import { ProductUrlFetcher } from './components/ProductUrlFetcher';
import {
  ManualProductData,
  ManualProductEntry,
} from './components/ManualProductEntry';
import { useQueryClient } from '@tanstack/react-query';
import log from 'loglevel';
import { useUrlArrayState } from '../../hooks/useUrlState';
import {
  useMostRecentlyCreatedAnalysisAccountNames,
  useReferrer,
} from '../../lib/hooksLib';
import RecentProductAnalyses from './components/RecentProductAnalyses';
import SavedAnalysisDrawer from './components/SavedAnalysisDrawer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SelectExpertList from './shared/SelectExpertList';
import { useAuth } from '../../hooks/use-auth';
import { useGetTrustedAccountsQuery } from '../../hooks/trusted-accounts-hooks';
import { useNavigate } from 'react-router-dom';
import { useSegment } from '../../contexts/segment-context';

export default function AnalyzePage() {
  const navigate = useNavigate();
  const segment = useSegment();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, isGuest } = useAuth();
  const [urlAccountNames] = useUrlArrayState([], 'accounts');
  const { data: trustedAccounts } = useGetTrustedAccountsQuery();
  const queryClient = useQueryClient();
  const { displayedAccounts, iniSelectedAccounts } = getInitialAccounts(
    trustedAccounts?.map((a) => a.account_name),
    urlAccountNames,
    defaultAccountNames,
  );

  const createAnalysisStubMutation = useCreateAnalysisStubMutation();

  // For guests, this immediately saves the account names in the URL in case they
  // create an account without actually conducting an analysis first
  useMostRecentlyCreatedAnalysisAccountNames();

  const [selectedAccountNames, setSelectedAccountNames] =
    useState<string[]>(iniSelectedAccounts);

  const iniNames = iniSelectedAccounts.join(',');
  useEffect(() => {
    setSelectedAccountNames(iniNames.split(','));
  }, [iniNames]);

  // If there's only a single account in the URL, assume that's a referrer
  const [referrer, setReferrer] = useReferrer();
  useEffect(() => {
    if (urlAccountNames.length === 1) setReferrer(urlAccountNames[0]);
  }, [setReferrer, urlAccountNames]);

  // Whether to skip parsing the page and allow the user to enter information
  // directly
  const [manualMode, setManualMode] = useState(false);

  useEffect(() => {
    document.title = 'CountOn - Analyze a page';
  }, []);

  const handleStartAnalysis = (
    widgetUrl?: string,
    manualProductData?: ManualProductData,
  ) => {
    segment.track('Analysis Stub Started', {
      account_names: selectedAccountNames,
      mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
    });
    createAnalysisStubMutation.mutate(
      {
        url: widgetUrl,
        mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
        account_names: selectedAccountNames,
      },
      {
        onSuccess: (data) => {
          const analysisId = data.analysis.analysis_id;
          log.debug('Stub analysis finished', data);
          // Precache the query data so when the results page loads, it's read
          // to go
          queryClient.setQueryData(
            ['get-saved-analysis', analysisId],
            data.analysis,
          );
          segment.track('Analysis Stub Completed', {
            analysis_id: analysisId,
            account_names: selectedAccountNames,
            mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
          });
          // Load the results viewer page. With the state set, it will trigger
          // the full analysis, and the URL will then be set right so they can
          // reload the page or come back later and the analysis will continue.
          navigate(`/analyze-page/${analysisId}`, {
            state: {
              mode: manualMode ? AnalysisMode.MANUAL : AnalysisMode.URL,
              analysisStub: data.analysis,
              manualProductData,
            },
          });
        },
      },
    );
  };

  const showSources = (user && trustedAccounts) || isGuest;
  const showUrlOrManual = showSources && selectedAccountNames.length > 0;

  return (
    <SectionContainer
      backgroundColor="transparent"
      minHeight={1000}
      sx={{ py: { xs: 2, sm: 5 } }}
    >
      <SavedAnalysisDrawer open={drawerOpen} setOpen={setDrawerOpen}>
        <Stack width={1} spacing={1} direction="column" alignItems={'center'}>
          <AnalysisHeader onClick={() => setDrawerOpen(!drawerOpen)} />
          {showSources ? (
            <SelectExpertList
              accountNames={displayedAccounts}
              selectedAccountNames={selectedAccountNames}
              setSelectedAccountNames={setSelectedAccountNames}
            />
          ) : (
            <Typography>Loading sources...</Typography>
          )}
          {showUrlOrManual && (
            <Box sx={{ width: 1, maxWidth: 1000 }}>
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'flex-end'}
                spacing={1}
                sx={{ my: 1 }}
              >
                <Button
                  onClick={() => setManualMode(!manualMode)}
                  sx={{ width: 300, fontWeight: 600 }}
                >
                  {manualMode
                    ? 'Switch to entering a URL'
                    : 'Switch to entering details manually'}
                </Button>
              </Stack>
              {manualMode ? (
                <ManualProductEntry
                  onAnalyzeClick={(mpd) => handleStartAnalysis(null, mpd)}
                  disabled={createAnalysisStubMutation.isLoading}
                />
              ) : (
                <ProductUrlFetcher onStart={handleStartAnalysis} />
              )}
            </Box>
          )}
          {selectedAccountNames.length === 0 && (
            <Typography sx={{ pt: 5, fontSize: 22, fontWeight: 600 }}>
              Please select at least one expert to analyze a product
            </Typography>
          )}
          <Box sx={{ py: 4 }} />
          <RecentProductAnalyses account_name={referrer} />
        </Stack>
      </SavedAnalysisDrawer>
    </SectionContainer>
  );
}

const AnalysisHeader = ({ onClick }) => {
  return (
    <Stack direction="column" spacing={1} sx={{ width: 1, maxWidth: 1000 }}>
      <Typography
        fontSize={{ xs: 32, sm: 48 }}
        fontWeight={600}
        lineHeight={{ xs: 1.2, sm: 1.2 }}
      >
        See beyond labels
      </Typography>
      <Typography fontSize={{ xs: 18, sm: 28 }} fontWeight={500}>
        Instant, personalized ingredient analysis from sources you trust.
      </Typography>
      <Button
        sx={{ alignSelf: 'flex-start' }}
        startIcon={<ListAltIcon />}
        onClick={onClick}
      >
        Your Previous Analyses
      </Button>
    </Stack>
  );
};

const defaultAccountNames = ['ewg', 'gimmethegoodstuff', 'made-safe'];

function appendUnique<T>(array1: T[], array2: T[]): T[] {
  return array1.concat(array2.filter((item) => !array1.includes(item)));
}

const getInitialAccounts = (
  trustedAccounts: string[] | null,
  urlAccounts: string[],
  defaultAccounts: string[],
) => {
  // If trustedAccounts is empty, assume the user is not logged in
  const isGuest = !trustedAccounts || trustedAccounts.length === 0;
  const urlAccountsPresent = urlAccounts.length > 0;
  if (isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(urlAccounts, defaultAccounts),
      iniSelectedAccounts: urlAccounts,
    };
  if (isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: defaultAccounts,
      iniSelectedAccounts: defaultAccounts,
    };
  if (!isGuest && urlAccountsPresent)
    return {
      displayedAccounts: appendUnique(trustedAccounts, urlAccounts),
      iniSelectedAccounts: trustedAccounts,
    };
  if (!isGuest && !urlAccountsPresent)
    return {
      displayedAccounts: trustedAccounts,
      iniSelectedAccounts: trustedAccounts,
    };
  throw new Error('Invalid state');
};
