import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useSegment } from '../../../contexts/segment-context';

interface ProductUrlFetcherProps {
  onStart: (url: string) => void;
}

export function ProductUrlFetcher({ onStart }: ProductUrlFetcherProps) {
  const [urlText, setUrlText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const segment = useSegment();

  const handleStart = () => {
    segment.track('Page Fetch Started', { url: urlText });
    setDisabled(true);
    onStart(urlText);
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <PaperAnalyze>
      <Stack width={1} spacing={1} direction="column">
        <Typography
          fontWeight={500}
          fontSize={{ xs: 22, sm: 24 }}
          lineHeight={1.3}
        >
          Enter any product webpage
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth={true}
            disabled={disabled}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                handleStart();
              }
            }}
            variant="filled"
            placeholder="http..."
            aria-label="product URL"
            value={urlText}
            onChange={(e) => setUrlText(e.target.value)}
            inputProps={{
              name: 'counton-analyze',
              style: inputStyle,
              enterKeyHint: 'analyze',
            }}
            InputProps={{
              hiddenLabel: true,
              disableUnderline: true,
              endAdornment: urlText && !disabled && (
                <InputAdornment position="end" onClick={() => setUrlText('')}>
                  <CloseIcon
                    sx={{ color: 'black', fontSize: { xs: 20, md: 30 } }}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: 6,
                px: 2.5,
                py: '10px',
              },
            }}
          />
          <Button
            onClick={handleStart}
            disabled={!isValidUrl(urlText) || disabled}
          >
            Start!
          </Button>
        </Stack>
      </Stack>
    </PaperAnalyze>
  );
}

const inputStyle = {
  fontSize: 16,
  autocomplete: 'off',
  autocorrect: 'off',
  autocapitalize: 'none',
  spellcheck: 'false',
  padding: 0,
};
