import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';
import BlurredBox from '../Analysis/components/BlurredContainer';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  useScrollTrigger,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { closeParent } from '../../pages/extension/communication/extension-hooks';
import logoUrl from '../../assets/logos/CountOn_primary.png';

export default function ExtLayout() {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const segment = useSegment();
  useEffect(() => {
    segment.page('Extension', location.pathname, { layout: 'ext' });
  }, [location.pathname, segment]);

  return (
    <BlurredBox minHeight={0}>
      <Outlet context={[query, setQuery]} />
    </BlurredBox>
  );
}

export const ExtensionHeader = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5,
  });

  return (
    <AppBar
      position={'sticky'}
      sx={{
        top: 0,
        backgroundColor: 'transparent',
      }}
      elevation={trigger ? 1 : 0}
    >
      <Stack
        direction="row"
        spacing={0}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: 1,
          height: 50,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            zIndex: 1001,
          }}
        >
          <IconButton onClick={closeParent}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component={'img'} src={logoUrl} height={50} />
      </Stack>
    </AppBar>
  );
};
