import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import useWebSocket from 'react-use-websocket';
import config from '../../../configs/aws-config';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import icon from 'src/assets/logos/counton_star.svg';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const wsUrl = config.websocket.URL;

type IdJsonMessage = {
  connectionId: string;
};

type StatusMessage = {
  task: string[];
  details: string;
  state: string;
};

type StatusUpdatesProps = {
  setConnectionId: (id: string) => void;
  title?: string;
  topics?: string[];
  visible?: boolean;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  error?: string;
};

export default function StatusUpdates({
  title = 'Status Updates',
  topics = ['fetch url', 'inspect page', 'summary'],
  setConnectionId,
  visible = true,
  expanded = true,
  setExpanded = () => {},
  error,
}: StatusUpdatesProps) {
  const [messageHistory, setMessageHistory] = useState<StatusMessage[]>([]);

  const [status, setStatus] = useState<Record<string, { state: string }>>({});

  useEffect(() => {
    // Initializes the `status` state with an object where each key is a topic
    // and its value is an object containing the state of the topic. The first
    // topic is set to 'running', and all other topics are set to 'idle'.
    if (topics.length === 0) return;
    const s = topics.reduce((acc, topic, index) => {
      acc[topic] = { state: index === 0 ? 'running' : 'idle' };
      return acc;
    }, {});
    setStatus(s);
  }, [topics]);

  const [ingredientMessages, setIngredientMessages] = useState<StatusMessage[]>(
    [],
  );

  const [summaryMessages, setSummaryMessages] = useState<StatusMessage[]>([]);
  const [inspectMessages, setInspectMessages] = useState<StatusMessage[]>([]);

  const [fetchMessages, setFetchMessages] = useState<StatusMessage[]>([]);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket<
    IdJsonMessage | StatusMessage
  >(wsUrl, {
    onOpen: (e: WebSocketEventMap['open']) => log.debug('ws opened', e),
    onError: (e) => log.error('ws error', e),
    shouldReconnect: () => true,
    onMessage: (msg: WebSocketEventMap['message']) =>
      log.trace('ws message', msg),
    onClose: (e) => {
      log.debug('ws closed, setting connect Id to empty string', e);
      setConnectionId('');
    },
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 60000, // TODO: what should this be? Interact with shouldReconnect?
      interval: 29000,
    },
  });

  useEffect(() => {
    sendJsonMessage({ action: 'sendmessage', data: 'get_connection_id' });
  }, [sendJsonMessage]);

  useEffect(() => {
    if (!lastJsonMessage) return;
    log.trace('lastJsonMessage', lastJsonMessage);
    if (isIdJsonMessage(lastJsonMessage)) {
      setConnectionId(lastJsonMessage.connectionId);
    } else {
      setMessageHistory((prev) => prev.concat(lastJsonMessage));
      if (lastJsonMessage.task.length === 1) {
        log.debug('Top level JsonMessage received', lastJsonMessage);
        const step = lastJsonMessage.task[0];
        setStatus((prev) => ({
          ...prev,
          [step]: { state: lastJsonMessage.state },
        }));
      } else {
        const step = lastJsonMessage?.task?.shift();
        if (step === 'fetch url')
          setFetchMessages((prev) => prev.concat(lastJsonMessage));
        if (step === 'inspect page')
          setInspectMessages((prev) => prev.concat(lastJsonMessage));
        if (step === 'summary')
          setSummaryMessages((prev) => prev.concat(lastJsonMessage));
        if (step === 'ingredients')
          setIngredientMessages((prev) => prev.concat(lastJsonMessage));
      }
    }
  }, [lastJsonMessage, setConnectionId]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  log.trace({
    messageHistory,
    fetchMessages,
    inspectMessages,
    summaryMessages,
    ingredientMessages,
    status,
    topics,
  });

  // This manages the websocket, so we want it always mounted even if it's not
  // shown
  if (!visible) return null;

  return (
    <PaperAnalyze sx={{ p: 0, m: 0 }}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
        sx={{
          bgcolor: 'pastel.light',
          px: { xs: 0, md: 0 },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ m: 0 }}>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <CountOnStar />
            <Typography
              fontWeight={500}
              fontSize={{ xs: 20, sm: 24 }}
              lineHeight={{ xs: 1.25, md: 2 }}
            >
              {title}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Stack
            sx={{ ml: { xs: 1, sm: 3 } }}
            direction="column"
            spacing={{ xs: 0.5, sm: 1 }}
          >
            {status['fetch url'] && (
              <StatusItem
                title="Retrieving the page"
                state={status['fetch url'].state}
                messages={fetchMessages}
              />
            )}
            {status['inspect page'] && (
              <StatusItem
                title="Inspecting page contents"
                state={status['inspect page'].state}
                messages={inspectMessages}
              />
            )}
            {status['summary'] && (
              <StatusWithMessages
                title="Finding product reviews"
                state={status['summary'].state}
                messages={summaryMessages}
              />
            )}
            {status['ingredients'] && (
              <StatusWithMessages
                title="Analyzing ingredients"
                state={status['ingredients'].state}
                messages={ingredientMessages}
              />
            )}
          </Stack>
          <Box sx={{ mt: 3 }}>{error && <ErrorMessage />}</Box>
        </AccordionDetails>
      </Accordion>
    </PaperAnalyze>
  );
}

type StatusItemProps = {
  title: string;
  state: string;
  messages?: StatusMessage[];
};
const StatusItem = ({ title, state, messages = [] }: StatusItemProps) => {
  const _messages = [];
  messages.forEach((msg) => {
    const present = _messages.find((x) => x.title === msg.task[0]);
    if (present) {
      present.details = msg.details;
      present.state = msg.state;
    } else {
      _messages.push({
        title: msg.task[0],
        details: msg.details,
        state: msg.state,
      });
    }
  });

  // If the overall state is complete, all subtasks should show complete
  // unless the task has an error
  const loadingState = (itemState) => {
    if (state === 'complete') {
      return itemState === 'error' ? 'error' : 'complete';
    }
    return itemState;
  };

  return (
    <Stack direction="row" alignContent={'center'} spacing={1}>
      <Box>
        <LoadingWithComplete state={state} size={24} />
      </Box>
      <Box>
        <Typography sx={{ color: state === 'idle' ? 'grey80' : 'black' }}>
          {title}
        </Typography>
        <Box sx={{ maxHeight: 100, overflow: 'auto' }}>
          <Stack direction="column">
            {_messages.map((x) => (
              <Stack
                key={x.title + x.expert}
                direction="row"
                spacing={1}
                alignItems={'center'}
              >
                <LoadingWithComplete
                  state={loadingState(x.state)}
                  size={14}
                  child={true}
                />
                <Typography fontSize={14}>{x.details}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

type StatusWithMessagesProps = {
  title: string;
  state: string;
  messages: StatusMessage[];
};
const StatusWithMessages = ({
  title,
  state,
  messages,
}: StatusWithMessagesProps) => {
  const ingredientExperts = [];
  messages.forEach((msg) => {
    const present = ingredientExperts.find(
      (x) => x.title === msg.task[0] && x.expert === msg.task[1],
    );
    if (present) {
      present.state = msg.state;
    } else {
      ingredientExperts.unshift({
        title: msg.task[0],
        expert: msg.task[1],
        state: msg.state,
        details: msg.details,
      });
    }
  });
  ingredientExperts.sort((a, b) => b.state.localeCompare(a.state));

  // If the overall state is complete, all subtasks should show complete
  // unless the task has an error
  const loadingState = (itemState) => {
    if (state === 'complete') {
      return itemState === 'error' ? 'error' : 'complete';
    }
    return itemState;
  };

  return (
    <Stack direction="row" alignContent={'center'} spacing={1}>
      <Box>
        <LoadingWithComplete state={state} size={24} />
      </Box>
      <Box>
        <Typography sx={{ color: state === 'idle' ? 'grey80' : 'black' }}>
          {title}
        </Typography>
        <Box sx={{ maxHeight: 150, overflow: 'auto' }}>
          <Stack direction="column">
            {ingredientExperts.map((x) => (
              <Stack
                key={x.title + x.expert}
                direction="row"
                spacing={1}
                alignItems={'center'}
              >
                <LoadingWithComplete
                  state={loadingState(x.state)}
                  size={14}
                  child={true}
                />
                <Typography fontSize={14}>
                  {x.title} ({x.details}
                  {x.expert && ' from ' + x.expert})
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

const LoadingWithComplete = ({ state, size = 18, child = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      }}
    >
      {state === 'error' && (
        <CloseIcon sx={{ width: 1, height: 1, color: 'red' }} />
      )}
      {state === 'running' && <CircularProgress size={'90%'} />}
      {state === 'complete' &&
        (child ? (
          <CheckIcon sx={{ color: 'primary.main', width: 1, height: 1 }} />
        ) : (
          <CheckCircleOutlineOutlinedIcon
            sx={{ color: 'primary.main', width: 1, height: 1 }}
          />
        ))}
    </Box>
  );
};

const CountOnStar = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 2,
        bgcolor: 'primary.main',
        p: 1,
      }}
    >
      <img src={icon} alt="" />
    </Box>
  );
};

function isIdJsonMessage(
  message: IdJsonMessage | StatusMessage,
): message is IdJsonMessage {
  return 'connectionId' in message;
}

const ErrorMessage = () => {
  return (
    <PaperAnalyze>
      <Box>
        <Typography fontSize={26} fontWeight={600} textAlign={'center'}>
          Sorry!
        </Typography>
        <Typography>
          Sorry about the error. We've logged it. Here are a few options you can
          try:
        </Typography>
        <ul>
          <li>
            <Typography>Refresh the page and try again.</Typography>
          </li>
          <li>
            <Typography>
              It might be a problem with the supplied website. You can try
              finding the product at a different website and trying again.
            </Typography>
          </li>
          <li>
            <Typography>
              You can email us at{' '}
              <a href="mailto:support@joincounton.com">
                support@joincounton.com
              </a>
              . Please remember this is a BETA service and we're a very small
              team! We promise to get back to you, but it might take a day.
            </Typography>
          </li>
        </ul>
      </Box>
    </PaperAnalyze>
  );
};
