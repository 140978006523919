import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import StartIcon from '@mui/icons-material/Start';
import Drawer from '@mui/material/Drawer';
import { useSearchProductAnalyses } from '../../../hooks/ai/search-product-analyses-hook';
import { useAuth } from '../../../hooks/use-auth';
import { SavedProductAnalysis } from '../../../types/saved_product_analyses';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useGuestId } from '../../../hooks/guest-id-hook';

export default function SavedAnalysisDrawer({ open, setOpen, children }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      id="saved-analysis-drawer-container"
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Drawer
        variant="temporary"
        anchor={isSm ? 'left' : 'bottom'}
        open={open}
        onClose={handleDrawerClick}
        sx={{
          '& .MuiDrawer-paper': { backgroundColor: '#F0F0F2' },
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Box
          sx={{
            mt: { xs: 0, sm: 10 },
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            onClick={handleDrawerClick}
            sx={{ m: { xs: 0, sm: 2 }, p: { xs: 1, sm: 2 } }}
          >
            <StartIcon
              sx={{ transform: `rotate(${isSm ? 180 : 90}deg)`, fontSize: 30 }}
            />
          </IconButton>
        </Box>
        <YourPreviousAnalyses onClick={() => setOpen(false)} />
      </Drawer>
      {/* Holy Shit, the minWidth: 0 is required to prevent fucking annoying horizontal scroll bars. I spent way too long figuring out this shit. */}
      <Box
        component="main"
        id="saved-analysis-drawer-main"
        sx={{ minWidth: 0, width: 1, margin: '0 auto' }}
      >
        {children}
      </Box>
    </Box>
  );
}

const YourPreviousAnalyses = ({ onClick }) => {
  const { user, isGuest } = useAuth();
  const { guest_id } = useGuestId();
  const query = useSearchProductAnalyses(
    null,
    user?.active_account_id,
    guest_id,
    isGuest || !!user?.user_id,
  );

  if (query.isLoading)
    return <Box sx={{ px: 2, py: 1, width: 350 }}>Loading...</Box>;

  if (query.isError) return <Box sx={{ px: 2, py: 1, width: 350 }}>Error!</Box>;

  const analyses = query.data?.analyses || [];

  if (analyses.length === 0)
    return (
      <Box sx={{ px: 2, py: 1, width: 350 }}>
        <Typography>
          Previous analyses you've created will be displayed here.
        </Typography>
      </Box>
    );

  const { recent, middle, older } = categorizeItemsByDate(analyses);

  return (
    <Box sx={{ px: 2, py: 1, width: 350, height: { xs: 300, sm: 1 } }}>
      {recent.length > 0 && (
        <PriorAnalyses
          analyses={recent}
          onClick={onClick}
          title="Previous 7 days"
        />
      )}
      {middle.length > 0 && (
        <PriorAnalyses
          analyses={middle}
          onClick={onClick}
          title="Previous 30 days"
        />
      )}
      {older.length > 0 && (
        <PriorAnalyses analyses={older} onClick={onClick} title="Older" />
      )}
    </Box>
  );
};

type PriorAnalysisProps = {
  title: string;
  analyses: SavedProductAnalysis[];
  onClick?: () => void;
};
function PriorAnalyses({ analyses, title, onClick }: PriorAnalysisProps) {
  const { analysis_id } = useParams();

  const activeSx = {
    borderRadius: 12,
    background: '#D9D9D9',
  };

  const handleClick = () => {
    onClick();
  };

  const getManualName = (analysis: SavedProductAnalysis) => {
    if (analysis.url) return `${analysis.brand} | ${analysis.title}`;

    let title = 'Manual:';
    if (analysis.brand) title += ` ${analysis.brand}`;
    if (analysis.title) title += ` ${analysis.title}`;
    if (analysis.result.ingredients)
      title += ` ${Object.keys(analysis.result.ingredients).join(', ')}`;
    return title;
  };

  return (
    <Box sx={{ mb: 7 }}>
      <Typography
        sx={{
          color: 'greyCustom.main',
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '30px',
          px: 2,
        }}
      >
        {title}
      </Typography>
      <Stack direction="column" spacing={1}>
        {analyses.map((analysis) => (
          <Typography
            component={Link}
            to={`/analyze-page/${analysis.analysis_id}`}
            key={analysis.analysis_id}
            onClick={handleClick}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: { xs: 19, sm: 19 },
              textDecoration: 'none',
              color: 'inherit',
              px: 2,
              ...(analysis.analysis_id === analysis_id ? activeSx : {}),
            }}
          >
            {getManualName(analysis)}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
}

function parsePostgresTimestamp(timestamp: string): Date {
  // Replace space with 'T' and add 'Z' to indicate UTC time
  return new Date(timestamp.replace(' ', 'T') + 'Z');
}

function filterByDateRange(
  priorAnalyses: SavedProductAnalysis[],
  from: string,
  to: string,
) {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  return priorAnalyses.filter((obj) => {
    const objDate = parsePostgresTimestamp(obj.created_at);
    return objDate >= fromDate && objDate <= toDate;
  });
}

function categorizeItemsByDate(priorAnalyses: SavedProductAnalysis[]) {
  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const thirtyDaysAgo = new Date(now);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const recent = filterByDateRange(
    priorAnalyses,
    oneWeekAgo.toISOString(),
    now.toISOString(),
  );
  const middle = filterByDateRange(
    priorAnalyses,
    thirtyDaysAgo.toISOString(),
    oneWeekAgo.toISOString(),
  );
  const older = priorAnalyses.filter((obj) => {
    const objDate = parsePostgresTimestamp(obj.created_at);
    return objDate < thirtyDaysAgo;
  });

  return {
    recent,
    middle,
    older,
  };
}
