export enum SavedAnalysisInputVersion {
  V0 = 'v0',
  V1 = 'v1',
}

export enum SavedAnalysisResultVersion {
  V0 = 'v0',
  V1 = 'v1',
}

export enum AnalysisStatus {
  INITIALIZED = 'initialized',
  ANALYZING_PRODUCT = 'analyzing_product',
  PRODUCT_ANALYZED = 'product_analyzed',
  INGREDIENTS_ANALYZED = 'ingredients_analyzed',
  ANALYZING_INGREDIENTS = 'analyzing_ingredients',
  ERROR = 'error',
}

export type SavedProductAnalysis = {
  analysis_id: string;
  user_id: string | null;
  guest_id: string | null;
  account_names: string[];
  status: AnalysisStatus;
  brand: string | null;
  title: string | null;
  url: string | null;
  input: AnalysisInputs;
  result: AnalyzeProductResult;
  created_at: string;
};

export type AnalysisInputs = {
  brand?: string;
  title?: string;
  description?: string;
  category?: string;
  ingredients?: Ingredient[];
  image_url?: string;
  isPersonalCareProduct?: boolean;
};

export type Ingredient = {
  name: string;
  description: string;
  attributes: string[] | null;
  percentage: number | null;
};

export type ExpertIngredientAnalysis = {
  summary: string;
  score?: ExpertScore;
  ingredient: string;
  run_id?: string;
};

export type ExpertScore = {
  value: string | string[] | number[];
  system?: string;
};

export type ExpertProductAnalysis = {
  product_summary: string;
  score?: ExpertScore;
  brand?: string;
  title?: string;
  run_id?: string;
};

export type AnalyzeProductResult = {
  brand?: string;
  title?: string;
  description?: string;
  //run_id?: string;
  analysis_id?: string;
  experts: { name: string; ingredient_analyzer?: boolean }[];
  category?: string;
  isPersonalCareProduct?: boolean;
  image_url?: string;
  ingredients?: {
    [ingredientName: string]: {
      [expertAccountName: string]: ExpertIngredientAnalysis;
    };
  };
  product_summaries?: {
    [expertName: string]: ExpertProductAnalysis;
  };
};

// *****************************************************************************
// An older version of the saved product analysis
// *****************************************************************************

export type ExpertIngredientAnalysisV0 = {
  summary: string;
  score?: number[] | string[] | string;
  ingredient: string;
};

export type AnalyzeProductResultV0 = {
  brand?: string;
  title?: string;
  description?: string;
  experts: { name: string; ingredient_analyzer?: boolean }[];
  category?: string;
  ingredients: {
    [ingredientName: string]: {
      [expertAccountName: string]: ExpertIngredientAnalysisV0;
    };
  };
  summary: {
    [expertName: string]: string;
  };
};
