import log from 'loglevel';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { PaperAnalyze } from '../shared/PaperAnalyze';

export enum PageInspectorStatus {
  IDLE = 'idle',
  RUNNING = 'running',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export type ManualProductData = {
  brand?: string;
  title?: string;
  description?: string;
  ingredients?: string;
};

type PageInspectorProps = {
  onAnalyzeClick: (manualProductData: ManualProductData) => void;
  disabled?: boolean;
};
export function ManualProductEntry({
  onAnalyzeClick,
  disabled = false,
}: PageInspectorProps) {
  const [manualProductData, setManualProductData] = useState<ManualProductData>(
    {},
  );
  const handleAnalyzeClick = async () => {
    log.debug('onAnalyzeClick');
    onAnalyzeClick(manualProductData);
  };

  // Function that takes a key and returns a function that sets the value of
  // that key in the manualProductData state
  const handleChange =
    (key: keyof ManualProductData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setManualProductData((prev) => ({
        ...prev,
        [key]: event.target.value,
      }));
    };

  const isDisabled =
    disabled ||
    (!manualProductData.brand &&
      !manualProductData.title &&
      !manualProductData.description &&
      !manualProductData.ingredients);

  return (
    <PaperAnalyze sx={{ p: 2, width: 1 }}>
      <Typography
        noWrap
        sx={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        Enter product details
      </Typography>

      <Typography sx={{ mb: 3 }}>
        Enter the product details below. The brand and product title are used to
        find reviews. The description helps summarize ingredient information.
        You can enter as much or as little as you like.
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextField
          label="Brand"
          disabled={disabled}
          onChange={handleChange('brand')}
        />
        <TextField
          label="Product Title"
          disabled={disabled}
          onChange={handleChange('title')}
        />
        <TextField
          label="Product Description"
          multiline
          maxRows={2.6}
          disabled={disabled}
          onChange={handleChange('description')}
        />
        <TextField
          label="Ingredients"
          helperText={
            !disabled &&
            'You can just copy/paste the block of ingredients, no need to format it! Make sure to include the "* Organic" or other stuff that sometimes comes after the ingredient list.'
          }
          multiline
          maxRows={8.6}
          minRows={3}
          disabled={disabled}
          onChange={handleChange('ingredients')}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent={'center'}
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          onClick={handleAnalyzeClick}
          disabled={isDisabled}
          sx={{
            lineHeight: 1.3,
            width: 180,
            fontWeight: 600,
          }}
        >
          Start Analysis
        </Button>
      </Stack>
    </PaperAnalyze>
  );
}
