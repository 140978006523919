import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import SectionContainer from 'src/components/SectionContainer';
import { useAuth } from '../../../hooks/use-auth';
import log from 'loglevel';
import { logError } from 'src/lib/errorLib';
import { useUpdateExtInstalledMutation } from 'src/hooks/account-hooks';

export default function AfterUninstall() {
  const { user, isAuthenticated } = useAuth();
  const { mutate } = useUpdateExtInstalledMutation(user?.active_account_id);

  useEffect(() => {
    const body = {
      ext_installed: false,
    };
    if (isAuthenticated) {
      mutate(body, {
        onError: (err) => {
          logError(err, { message: 'Error updating ext_installed' });
        },
      });
    }
  }, [isAuthenticated, mutate]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  log.trace('AfterUninstall render');
  return (
    <SectionContainer backgroundColor="pastel.light">
      <Box
        sx={{
          height: 850,
          py: 4,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
          Sorry to see you go! We'd love your feedback on how we can improve.
          Email us at{' '}
          <a href="mailto:support@joincounton.com">support@joincounton.com</a>
        </Typography>
      </Box>
    </SectionContainer>
  );
}
