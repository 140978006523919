import log from 'loglevel';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { logError } from '../../lib/errorLib';
import { API } from 'aws-amplify';
import { SavedProductAnalysis } from '../../types/saved_product_analyses';

function searchProductAnalyses(
  account_names?: string[],
  account_id?: string,
  guest_id?: string,
  limit?: number,
  offset?: number,
): Promise<{ analyses: SavedProductAnalysis[] }> {
  log.debug('Fetching saved product analyses for', account_names);
  const sp = new URLSearchParams();
  if (account_id) sp.set('account_id', account_id);
  if (guest_id) sp.set('guest_id', guest_id);
  if (account_names) sp.set('account_names', account_names.join(','));
  if (limit) sp.set('limit', limit.toString());
  if (offset) sp.set('offset', offset.toString());

  const apiPath = `${
    account_id ? '' : '/guest'
  }/product-analyses?${sp.toString()}`;
  return API.get('trustable', apiPath, {});
}

export function useSearchProductAnalyses(
  account_names?: string[],
  account_id?: string,
  guest_id?: string,
  enabled = true,
  limit = 15,
  offset = 0,
) {
  const queryClient = useQueryClient();
  const inputsReady =
    !!guest_id ||
    !!account_id ||
    (Array.isArray(account_names) && account_names.length > 0);

  const review = useQuery(
    ['search-analyses', account_names, account_id, guest_id],
    () =>
      searchProductAnalyses(account_names, account_id, guest_id, limit, offset),
    {
      enabled: inputsReady && enabled,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        log.debug('useSearchProductAnalyses:', data);
        // Cache each individual item so they return immediately when fetched
        // individually
        data.analyses.forEach((analysis) => {
          queryClient.setQueryData(
            ['get-saved-analysis', analysis.analysis_id],
            analysis,
          );
        });
      },
      onError: (error) => logError(error),
    },
  );
  return review;
}
