import { Button, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';

type NewAnalysisButtonProps = {
  urlAccountNames?: string[];
};
export default function NewAnalysisButton({
  urlAccountNames,
}: NewAnalysisButtonProps) {
  const location = useLocation();

  const url = `/analyze-page${
    urlAccountNames ? '?accounts=' + urlAccountNames.join(',') : location.search
  }`;

  return (
    <Button
      component={Link}
      to={url}
      startIcon={<AddCircleIcon />}
      variant="contained"
    >
      <Typography fontWeight={600}>Analyze another product</Typography>
    </Button>
  );
}
