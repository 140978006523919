import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { ExpertIngredientAnalysis } from '../../../types/saved_product_analyses';
import CountOnAvatar from '../../../components/avatar/CountOnAvatar';
import { IngredientsArray } from './ProductSummaryWidget';
import ClearIcon from '@mui/icons-material/Clear';
import log from 'loglevel';

const CELL_SIZE = 24;
const CELL_GAP = 0.5;

type IngredientMosaicProps = {
  ingredients: IngredientsArray;
  shownIngredients: IngredientsArray;
  setShownIngredients: (ingredients: IngredientsArray) => void;
};

export default function IngredientMosaic({
  ingredients,
  shownIngredients,
  setShownIngredients,
}: IngredientMosaicProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [byConcern, setByConcern] = useState(false);

  const [selectedExperts, setSelectedExperts] = useState<string[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
  const [selectedScores, setSelectedScores] = useState<number[]>([]);

  const experts = Object.keys(ingredients[0].experts);

  const sortedIngredients = useMemo(() => {
    let ingredientsArray = [...ingredients];

    if (byConcern) {
      return ingredientsArray.sort(
        (a, b) => getWorstRating(b.experts) - getWorstRating(a.experts),
      );
    } else {
      return ingredients;
    }
  }, [ingredients, byConcern]);

  useEffect(() => {
    const sortedIngredients = byConcern
      ? [
          ...ingredients.sort(
            (a, b) => getWorstRating(b.experts) - getWorstRating(a.experts),
          ),
        ]
      : [...ingredients.sort((a, b) => a.index - b.index)];

    setShownIngredients(
      filterIngredientData(
        sortedIngredients,
        selectedIngredients,
        selectedExperts,
      ),
    );
  }, [
    ingredients,
    byConcern,
    setShownIngredients,
    selectedIngredients,
    selectedExperts,
    selectedScores,
  ]);

  const handleIngredientClick = (ingredientName: string) => {
    setSelectedIngredients((prev) => toggleItemInArray(prev, ingredientName));
  };

  const handleExpertClick = (expertName: string) => {
    setSelectedExperts((prev) => toggleItemInArray(prev, expertName));
  };

  const handleScoreClick = (score: number) => {
    const selScores = toggleItemInArray(selectedScores, score);
    setSelectedScores(selScores);

    const filteredIngredients = ingredients
      .filter((ingredient) =>
        selScores.includes(getWorstRating(ingredient.experts)),
      )
      .map((x) => x.ingredient);

    setSelectedIngredients(filteredIngredients);
  };

  const handleClearFilters = () => {
    setSelectedIngredients([]);
    setSelectedExperts([]);
    setSelectedScores([]);
  };

  const isFilterActive =
    selectedExperts.length > 0 ||
    selectedScores.length > 0 ||
    selectedIngredients.length > 0;

  log.trace({
    isFilterActive,
    selectedIngredients,
    selectedExperts,
    selectedScores,
    shownIngredients,
  });
  if (!ingredients) return <div>Loading...</div>;

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {experts.length > 1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'sticky',
              left: 0,
              zIndex: 1,
              bgcolor: 'background.paper',
              pl: 0.5,
            }}
          >
            <Box sx={{ width: CELL_SIZE, height: CELL_SIZE + CELL_GAP * 8 }}>
              {isFilterActive && (
                <IconButton
                  onClick={handleClearFilters}
                  aria-label="clear-filters"
                  size="small"
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>

            {experts.map((expert) => (
              <Tooltip
                key={expert}
                title={<Typography color="inherit">{expert}</Typography>}
                placement="right"
                arrow
              >
                <Box
                  sx={{ cursor: experts.length > 1 && 'pointer', pr: 1 }}
                  onClick={() =>
                    experts.length > 1 && handleExpertClick(expert)
                  }
                >
                  <Box
                    sx={{
                      p: CELL_GAP / 2,
                      transition: 'transform 0.2s ease-in-out', // Add smooth transition
                      transform: selectedExperts.includes(expert)
                        ? 'translateX(-10px)'
                        : 'translateX(0)', // Apply shift based on selection
                    }}
                    onMouseEnter={() =>
                      !isFilterActive &&
                      experts.length > 1 &&
                      setShownIngredients(
                        ingredients.map((ing) => ({
                          ...ing,
                          experts: { [expert]: ing.experts[expert] },
                        })),
                      )
                    }
                    onMouseLeave={() =>
                      !isFilterActive &&
                      experts.length > 1 &&
                      setShownIngredients(ingredients)
                    }
                  >
                    <CountOnAvatar
                      account_name={expert}
                      sx={{
                        width: CELL_SIZE,
                        height: CELL_SIZE,
                        border: selectedExperts.includes(expert) ? 2 : 1,
                        borderColor: selectedExperts.includes(expert)
                          ? 'black'
                          : 'greyCustom.light',
                      }}
                    />
                  </Box>
                </Box>
              </Tooltip>
            ))}
          </Box>
        )}
        <Box sx={{ overflowX: 'auto', maxWidth: 1 }}>
          <Stack
            direction="column"
            justifyContent={'center'}
            sx={{ display: 'inline-block', minWidth: '100%' }}
          >
            <Box
              sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridAutoColumns: 'min-content',
              }}
            >
              {sortedIngredients.map((ing, colIndex) => (
                <Box
                  key={colIndex}
                  sx={{
                    display: 'grid',
                    gridTemplateRows: `repeat(${experts.length}, 1fr)`,
                  }}
                >
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          {ing.ingredient}
                        </Typography>
                        <Typography color="inherit">
                          Max concern:{' '}
                          {concernLabels[getWorstRating(ing.experts)]}
                        </Typography>
                      </>
                    }
                    placement="top"
                    arrow
                  >
                    <Box
                      sx={{ p: CELL_GAP / 2 }}
                      onMouseEnter={() =>
                        !isFilterActive && setShownIngredients([ing])
                      }
                      onMouseLeave={() =>
                        !isFilterActive && setShownIngredients(ingredients)
                      }
                      onClick={() => handleIngredientClick(ing.ingredient)}
                    >
                      <StyledCell
                        selected={selectedIngredients.includes(ing.ingredient)}
                        color={getColor(getWorstRating(ing.experts))}
                      />
                    </Box>
                  </Tooltip>
                  {experts.length > 1 &&
                    experts.map((expert, rowIndex) => (
                      <Box
                        sx={{
                          p: CELL_GAP / 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        key={expert + '-' + rowIndex}
                      >
                        <StyledCell
                          size={CELL_SIZE * 0.8}
                          sx={{ borderRadius: 3 }}
                          selected={
                            isFilterActive &&
                            (selectedIngredients.length === 0 ||
                              selectedIngredients.includes(ing.ingredient)) &&
                            (selectedExperts.length === 0 ||
                              selectedExperts.includes(expert))
                          }
                          color={getColor(
                            getCommonRating(expert, ing.experts[expert]),
                          )}
                        />
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Stack direction="row" spacing={0} justifyContent={'center'}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', width: 130, py: 1 }}
        >
          <Switch
            checked={byConcern}
            onChange={() => setByConcern(!byConcern)}
            size="small"
          />
          <Typography fontSize={12} lineHeight={1}>
            {byConcern ? 'By Concern' : 'Label Order'}
          </Typography>
        </Stack>
        {[3, 2, 1].map((score) => (
          <Box
            key={`legend-${score}`}
            sx={{ display: 'flex', alignItems: 'center', pr: 1 }}
            onMouseEnter={() => {
              if (isFilterActive) return;

              const filteredIngredients = ingredients.filter((ingredient) =>
                [score].includes(getWorstRating(ingredient.experts)),
              );

              setShownIngredients(filteredIngredients);
            }}
            onMouseLeave={() =>
              !isFilterActive && setShownIngredients(ingredients)
            }
            onClick={() => handleScoreClick(score)}
          >
            <LegendCell
              selected={selectedScores.includes(score)}
              score={score}
            />
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: selectedScores.includes(score)
                  ? 'underline'
                  : 'none',
              }}
            >
              {isSm ? legendLabels[score] : legendMobileLabels[score]}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

const legendLabels = ['Unknown', 'Low', 'Moderate', 'High concern'];
const legendMobileLabels = ['Unknown', 'Low', 'Mod', 'High'];
const concernLabels = ['Unknown', 'Low', 'Moderate', 'High'];

const StyledCell = styled(Box)<{
  color: string;
  selected: boolean;
  size?: number;
}>(({ color, selected, size = CELL_SIZE }) => ({
  width: size,
  height: size,
  backgroundColor: color,
  flexShrink: 0,
  cursor: 'pointer',
  border: selected ? '3px solid #000' : 'none',
  borderRadius: 4,
}));

const LegendCell = styled(Box)<{ selected: boolean; score: number }>(
  ({ selected, score }) => ({
    width: 16,
    height: 16,
    backgroundColor: getColor(score),
    marginRight: 8,
    cursor: 'pointer',
    border: selected ? '2px solid #000' : 'none',
    borderRadius: 3,
  }),
);

const getCommonRating = (
  expertName: string,
  analysis: ExpertIngredientAnalysis,
) => {
  const ratingNames = ['unknown', 'green', 'yellow', 'red'];

  const simpleMatch = ratingNames.findIndex(
    (rating) => rating === analysis?.score.value,
  );
  if (simpleMatch > -1) return simpleMatch;

  if (expertName === 'ewg' && Array.isArray(analysis.score.value)) {
    const upperBound = Number(analysis.score.value[1]);
    if (upperBound <= 2) return 1;
    if (upperBound <= 6) return 2;
    if (upperBound <= 10) return 3;
  }
  return 0;
};

const getWorstRating = (ratings: {
  [expertAccountName: string]: ExpertIngredientAnalysis;
}) => {
  const ratingValues = Object.entries(ratings).map(([expert, rating]) =>
    getCommonRating(expert, rating),
  );
  return ratingValues.reduce((acc, rating) => (rating > acc ? rating : acc));
};

const getColor = (rating: number) => {
  switch (rating) {
    case 3:
      return '#ef4444';
    case 2:
      return '#eab308';
    case 1:
      return '#22c55e';
    default:
      return '#e5e7eb';
  }
};

function toggleItemInArray<T extends string | number>(
  array: T[],
  item: T,
): T[] {
  return array.includes(item)
    ? array.filter((element) => element !== item)
    : [...array, item];
}

function filterIngredientData(
  data: IngredientsArray,
  ingredientFilter: string[] = [],
  expertFilter: string[] = [],
  //scoreFilter: number[] = [],
): any[] {
  return data.reduce((filteredData, item) => {
    // Check if the ingredient matches the filter (if any)
    const ingredientMatch =
      ingredientFilter.length === 0 ||
      ingredientFilter.includes(item.ingredient);

    if (!ingredientMatch) {
      return filteredData; // Skip this item if ingredient doesn't match
    }

    // Filter experts
    const filteredExperts: Record<string, any> = {};

    for (const expertName of expertFilter.length > 0
      ? expertFilter
      : Object.keys(item.experts)) {
      const review = item.experts[expertName];
      if (review) {
        filteredExperts[expertName] = review;
      }
    }

    // Only include this item if there are matching experts
    filteredData.push({
      ...item,
      experts: filteredExperts,
    });

    return filteredData;
  }, []);
}
