import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSegment } from 'src/contexts/segment-context';

export default function ExtPopupLayout() {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const segment = useSegment();
  useEffect(() => {
    segment.page('Extension', location.pathname, { layout: 'ext' });
  }, [location.pathname, segment]);

  return <Outlet context={[query, setQuery]} />;
}
