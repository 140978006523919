import { Box, Button, Stack, Typography } from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import { ManualProductData } from './components/ManualProductEntry';
import { useLocation, useParams } from 'react-router-dom';
import { useGetSavedProductAnalysis } from '../../hooks/ai/get-saved-product-analysis-hook';
import { PaperAnalyze } from './shared/PaperAnalyze';
import NewAnalysisButton from './shared/NewProductAnalysisButton';
import { ProductSummaryWidget } from './components/ProductSummaryWidget';
import SavedAnalysisDrawer from './components/SavedAnalysisDrawer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useMostRecentlyViewedAnalysisAccountNames } from '../../lib/hooksLib';
import {
  AnalysisStatus,
  SavedProductAnalysis,
} from '../../types/saved_product_analyses';
import { LoadingSpinner } from '../../components/loading-spinner';
import log from 'loglevel';
import SelectExpertList from './shared/SelectExpertList';
import StatusUpdates from './components/StatusUpdates';
import {
  AnalysisMode,
  useAddIngredientAnalysisMutation,
  useStartManualAnalysisMutation,
  useStartUrlAnalysisMutation,
} from '../../hooks/ai/analyze-page-fetch';
import { IngredientsInspector } from './components/IngredientsInspector';
import { useQueryClient } from '@tanstack/react-query';
import { useInIframe } from '../../hooks/util/in-iframe-hook';
import { ExtensionHeader } from '../../layouts/Extension/ExtLayout';
import { useSegment } from '../../contexts/segment-context';

export default function ViewSavedAnalysis() {
  const { analysis_id } = useParams();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setMostRecent] = useMostRecentlyViewedAnalysisAccountNames();

  useEffect(() => {
    document.title = 'CountOn - Analysis Results';
    log.debug('state:', location.state);
  }, [location.state]);

  const {
    data: analysis,
    isLoading,
    isError,
  } = useGetSavedProductAnalysis(analysis_id);

  useEffect(() => {
    if (analysis?.account_names) {
      setMostRecent(analysis.account_names);
    }
  }, [analysis, setMostRecent]);

  // If the state includes analysisInputs and the analysis is only just
  // initialized, then it's time kick the analysis off.
  const newAnalysis =
    analysis?.status === AnalysisStatus.INITIALIZED &&
    !!location.state?.analysisStub;

  const analysisStub: SavedProductAnalysis = location.state?.analysisStub;

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (isError) {
    return <ErrorScreen />;
  }

  if (newAnalysis) {
    return (
      <InProgressAnalysis
        analysisStub={analysisStub}
        mode={location.state.mode as AnalysisMode}
        manualProductData={location.state.manualProductData}
        markdown={location.state.markdown}
      />
    );
  }

  // Must be an existing analysis
  return (
    <SavedAnalysisDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <AnalysisSummary
        analysis={analysis}
        onPreviousAnalysesClick={() => setDrawerOpen(!drawerOpen)}
      />
    </SavedAnalysisDrawer>
  );
}

type InProgressAnalysisProps = {
  analysisStub: SavedProductAnalysis;
  mode: AnalysisMode;
  manualProductData: ManualProductData;
  markdown?: string;
};

const InProgressAnalysis = ({
  analysisStub,
  mode,
  manualProductData,
  markdown,
}: InProgressAnalysisProps) => {
  const [inIframe] = useInIframe();
  const segment = useSegment();
  const queryClient = useQueryClient();
  const [statusTopics, setStatusTopics] = useState<string[]>([]);
  const [connectionId, setConnectionId] = useState<string>('');
  const [statusExpanded, setStatusExpanded] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [analysis, setAnalysis] = useState<SavedProductAnalysis | null>(null);

  const { mutate: startUrlAnalysis } = useStartUrlAnalysisMutation();
  const { mutate: startManualAnalysis } = useStartManualAnalysisMutation();

  useEffect(() => {
    if (!connectionId) return;

    if (mode === AnalysisMode.URL || mode === AnalysisMode.MARKDOWN) {
      segment.track('Product Analysis Started', {
        analysis_id: analysisStub.analysis_id,
        account_names: analysisStub.account_names,
        mode,
      });
      // If markdown is provided, don't show the fetch_url part.
      setStatusTopics(
        mode === AnalysisMode.URL
          ? ['fetch url', 'inspect page', 'summary']
          : ['inspect page', 'summary'],
      );

      startUrlAnalysis(
        {
          url: analysisStub.url,
          analysisId: analysisStub.analysis_id,
          accountNames: analysisStub.account_names,
          connectionId,
          markdown,
        },
        {
          onSuccess: (data) => {
            log.debug('startUrlAnalysis success', data);
            // Setting the query data here will cause the parent container to
            // update and display a saved analysis.
            queryClient.setQueryData(
              ['get-saved-analysis', data.analysis_id],
              data,
            );
            setAnalysis(data);
            setStatusTopics([]);
            segment.track('Product Analysis Completed', {
              analysis_id: data.analysis_id,
              brand: data.input?.brand,
              title: data.input?.title,
              account_names: analysisStub.account_names,
              mode,
            });
          },
          onError: (error) => {
            log.debug('startUrlAnalysis error', error);
            setError('Error analyzing URL');
          },
        },
      );
    } else {
      // Manual Mode
      const topics = [];
      if (manualProductData.brand || manualProductData.title) {
        topics.push('summary');
        segment.track('Product Analysis Started', {
          analysis_id: analysisStub.analysis_id,
          account_names: analysisStub.account_names,
          mode,
        });
      }
      // In manual mode, the user can provide product and/or ingredient information
      if (manualProductData.ingredients) {
        topics.push('ingredients');
        segment.track('Ingredients Analysis Started', {
          analysis_id: analysisStub.analysis_id,
          account_names: analysisStub.account_names,
          mode,
        });
      }
      setStatusTopics(topics);
      startManualAnalysis(
        {
          analysisId: analysisStub.analysis_id,
          accountNames: analysisStub.account_names,
          connectionId,
          ...manualProductData,
        },
        {
          onSuccess: (data) => {
            log.debug('startManualAnalysis success', data);
            // Setting the query data here will cause the parent container to
            // update and display a saved analysis.
            queryClient.setQueryData(
              ['get-saved-analysis', data.analysis_id],
              data,
            );
            if (manualProductData.brand || manualProductData.title) {
              segment.track('Product Analysis Completed', {
                analysis_id: data.analysis_id,
                account_names: data.account_names,
                brand: data.input?.brand,
                title: data.input?.title,
                mode,
              });
            }
            if (manualProductData.ingredients) {
              segment.track('Ingredients Analysis Completed', {
                analysis_id: data.analysis_id,
                account_names: data.account_names,
                mode,
              });
            }
            setAnalysis(data);
            setStatusTopics([]);
          },
          onError: (error) => {
            log.debug('startManualAnalysis error', error);
            setError('Error analyzing manual inputs');
          },
        },
      );
    }
  }, [
    analysisStub,
    mode,
    connectionId,
    queryClient,
    startUrlAnalysis,
    startManualAnalysis,
    manualProductData,
    markdown,
    segment,
  ]);

  return (
    <ViewContainer>
      {inIframe ? (
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <ExtensionHeader />
        </Box>
      ) : (
        <SelectExpertList
          accountNames={analysisStub.account_names}
          selectedAccountNames={analysisStub.account_names}
          disabled={true}
        />
      )}
      {analysis && <ProductSummaryWidget analysis={analysis} />}

      <Box
        sx={{
          display: statusTopics.length > 0 ? 'flex' : 'none',
          justifyContent: 'center',
          width: 1,
        }}
      >
        {/* Don't unmount StatusUpdates. It holds the websocket connection */}
        <StatusUpdates
          title={'Building your expert analysis'}
          topics={statusTopics}
          setConnectionId={setConnectionId}
          error={error}
          expanded={statusExpanded}
          setExpanded={setStatusExpanded}
        />
      </Box>
      {/*<JokeWidget
        pageContent={pageContent}
        productPageDetails={productPageDetails}
      />*/}
    </ViewContainer>
  );
};

type AnalysisSummaryProps = {
  analysis: SavedProductAnalysis;
  onPreviousAnalysesClick: () => void;
};
const AnalysisSummary = ({
  analysis,
  onPreviousAnalysesClick,
}: AnalysisSummaryProps) => {
  const isIngredientsAnalyzed = !!analysis?.result?.ingredients;
  const isAnalysisRunning =
    analysis?.status === AnalysisStatus.ANALYZING_INGREDIENTS ||
    analysis?.status === AnalysisStatus.ANALYZING_PRODUCT;

  const showAnalyzeIngredients =
    // If there's no URL, it was a manual analysis, so ingredients would have
    // been provided
    !isIngredientsAnalyzed && !isAnalysisRunning && !!analysis?.url;

  const [inIframe] = useInIframe();

  // console.log({
  //   analysis,
  //   showAnalyzeIngredients,
  //   isIngredientsAnalyzed,
  // });

  return (
    <ViewContainer>
      {inIframe && ( // Kinda janky, but works for now
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <ExtensionHeader />
        </Box>
      )}
      <Stack direction="row" sx={{ pt: 1, pb: { xs: 0, sm: 1.5 } }}>
        <Typography fontSize={{ xs: 14, sm: 19 }}>
          These AI summaries are in beta and may contain mistakes.
        </Typography>
      </Stack>
      {!inIframe && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          sx={{ mt: { xs: 0, sm: 2 }, width: 1, maxWidth: 1000 }}
        >
          <Button
            sx={{ alignSelf: 'flex-start' }}
            startIcon={<ListAltIcon />}
            onClick={onPreviousAnalysesClick}
          >
            Your Previous Analyses
          </Button>
          <NewAnalysisButton urlAccountNames={analysis.account_names || []} />
        </Stack>
      )}
      <ProductSummaryWidget analysis={analysis} />
      {showAnalyzeIngredients && (
        <AnalyzeIngredientsWidget analysis={analysis} />
      )}
      {isAnalysisRunning && (
        <AnalysisInProgress message={'There is an analysis in progress'} />
      )}
    </ViewContainer>
  );
};

type AnalyzeIngredientsWidgetProp = {
  analysis: SavedProductAnalysis;
};
function AnalyzeIngredientsWidget({ analysis }: AnalyzeIngredientsWidgetProp) {
  const queryClient = useQueryClient();
  const segment = useSegment();
  const [showStatus, setShowStatus] = useState(false);
  const [connectionId, setConnectionId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [ingredients, setIngredients] = useState<string>(() => {
    if (!analysis.input.ingredients) {
      return '';
    }
    return analysis.input.ingredients.map((x) => x.description).join('\n');
  });
  const addIngredientAnalysisMutation = useAddIngredientAnalysisMutation();

  const noIngredients =
    !analysis.input.ingredients || analysis.input.ingredients.length === 0;

  const handleAnalyzeIngredientsClick = () => {
    log.debug('Analyze ingredients clicked');
    const originalIngredients = analysis.input.ingredients
      .map((x) => x.description)
      .join('\n');
    const isIngredientsModified = ingredients !== originalIngredients;

    setShowStatus(true);
    segment.track('Ingredients Analysis Started', {
      analysis_id: analysis.analysis_id,
      brand: analysis.input?.brand,
      title: analysis.input?.title,
      account_names: analysis.account_names,
      mode: 'click',
    });
    addIngredientAnalysisMutation.mutate(
      {
        analysisId: analysis.analysis_id,
        connectionId: connectionId,
        ingredients: isIngredientsModified ? ingredients : undefined,
      },
      {
        onSuccess: (data) => {
          log.debug('addIngredientAnalysisMutation success', data);
          queryClient.setQueryData(
            ['get-saved-analysis', data.analysis_id],
            data,
          );
          segment.track('Ingredients Analysis Completed', {
            analysis_id: analysis.analysis_id,
            brand: analysis.input?.brand,
            title: analysis.input?.title,
            account_names: analysis.account_names,
            mode: 'click',
          });
          setShowStatus(false);
        },
        onError: (error) => {
          log.error('addIngredientAnalysisMutation error', error);
          setError('Error adding ingredient analysis');
        },
      },
    );
  };

  return (
    <Stack
      direction="column"
      alignItems={'center'}
      spacing={2}
      sx={{ width: 1, maxWidth: 1000, py: 3 }}
    >
      {!showStatus && (
        <>
          <Typography
            fontSize={{ xs: 20, sm: 24 }}
            fontWeight={600}
            textAlign={'center'}
          >
            {noIngredients
              ? 'No ingredients were found, but you can enter them manually below.'
              : `Want to go one level deeper? CountOn found ${analysis.input.ingredients?.length} ingredients`}
          </Typography>
          <Button variant="contained" onClick={handleAnalyzeIngredientsClick}>
            Analyze!
          </Button>
          <IngredientsInspector
            ingredients={ingredients}
            setIngredients={setIngredients}
          />
        </>
      )}
      <Box
        sx={{
          display: showStatus ? 'flex' : 'none',
          justifyContent: 'center',
          width: 1,
        }}
      >
        {/* Don't unmount StatusUpdates. It holds the websocket connection */}
        <StatusUpdates
          title={'Analyzing ingredients'}
          topics={['ingredients']}
          setConnectionId={setConnectionId}
          error={error}
        />
      </Box>
    </Stack>
  );
}

const LoadingScreen = () => {
  return (
    <ViewContainer>
      <PaperAnalyze sx={{ p: 4, width: 1, textAlign: 'center' }}>
        <LoadingSpinner />
      </PaperAnalyze>
    </ViewContainer>
  );
};

const ErrorScreen = () => {
  return (
    <ViewContainer>
      <PaperAnalyze sx={{ p: 4, width: 1, textAlign: 'center' }}>
        <Typography fontSize={22} fontWeight={600}>
          Sorry! Could not retrieve that analysis.
        </Typography>
      </PaperAnalyze>
    </ViewContainer>
  );
};

const ViewContainer = ({ children }) => {
  return (
    <SectionContainer
      backgroundColor="transparent"
      minHeight={1000}
      sx={{ py: 5 }}
    >
      <Stack width={1} spacing={1} direction="column" alignItems={'center'}>
        {children}
      </Stack>
    </SectionContainer>
  );
};

const AnalysisInProgress = ({ message }) => {
  return (
    <PaperAnalyze sx={{ p: 4, width: 1, textAlign: 'center' }}>
      <Typography fontSize={22} fontWeight={600}>
        {message}
      </Typography>
      <Typography>Try refreshing the page in a minute.</Typography>
    </PaperAnalyze>
  );
};
