import { Link, useSearchParams } from 'react-router-dom';
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { useSegment } from '../../../contexts/segment-context';
import { useDetectIsVisible } from '../communication/extension-hooks';
import ReviewSearchV3 from './reviews/ReviewSearchV3';
import AnalyzePageExt from './analysis/AnalyzePageExt';
import { ExtensionHeader } from '../../../layouts/Extension/ExtLayout';

enum ExpertiseMode {
  REVIEWS = 'reviews',
  ANALYZE = 'analyze',
}

const MODE_LOOKUP = {
  [ExpertiseMode.REVIEWS]: 0,
  [ExpertiseMode.ANALYZE]: 1,
};

const tabSx = { fontWeight: 600, fontSize: 18 };

/**
 * Displays reviews that are matched to a particular docTitle. This is meant to be used
 * in the extension flyout, but it can show up on joincounton as well when the host
 * page prevents iframes from being loaded.
 * @returns
 */
export default function Expertise() {
  const { isAuthenticated, isInitialized } = useAuth();
  const segment = useSegment();
  const [sp] = useSearchParams();
  const docTitle = sp.get('doc_title');
  const url = sp.get('url');
  const tab = sp.get('mode') as ExpertiseMode;
  const inExtension = sp.get('ext') !== 'false';
  //log.debug({ docTitle, url, tab });

  const [hasFired, setHasFired] = useState(false);
  const iframeOpen = useDetectIsVisible();

  const [activeTabIndex, setActiveTabIndex] = useState(MODE_LOOKUP[tab]);

  // This will fire even while the iframe is hidden. We can't use segment in the
  // core extension code, so this is our way of knowing that the side button
  // did appear to the user.
  useEffect(() => {
    segment.pageManual('Extension', '/side-button', { layout: 'ext' });
  }, [segment]);

  if (isInitialized && !isAuthenticated) return <LoginRequest />;

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
      }}
    >
      {inExtension && <ExtensionHeader />}
      <Stack direction="column" spacing={1} sx={{ mx: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTabIndex}
            onChange={(_, newValue) => setActiveTabIndex(newValue)}
            aria-label="Reviews and analysis tabs"
            centered
          >
            <Tab sx={tabSx} label="Reviews" {...a11yProps(0)} />
            <Tab sx={tabSx} label="AI Analysis" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={activeTabIndex} index={0}>
          <ReviewSearchV3 docTitle={docTitle} url={url} tab={tab} />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <AnalyzePageExt active={activeTabIndex === 1} />
        </TabPanel>
      </Stack>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ m: 0, p: 0 }}
      {...other}
    >
      {children}
    </Box>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const LoginRequest = () => {
  const segment = useSegment();

  useEffect(() => {
    segment.track('Login Banner Shown', { context: 'flyout', extension: true });
  }, [segment]);

  return (
    <Stack direction="column" spacing={2} alignItems={'center'}>
      <ExtensionHeader />
      <Typography
        sx={{
          width: 320,
          textAlign: 'center',
          fontSize: 19,
          lineHeight: '28px',
        }}
      >
        You're currently logged out, log in to see recommendations then refresh
        this page.
      </Typography>
      <Button
        component={Link}
        to="/login"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ fontSize: 18, fontWeight: 600, width: 320 }}
        variant="contained"
      >
        Log in
      </Button>
    </Stack>
  );
};
